import React from "react";

import * as styles from "./SymbolIndentedParagraph.module.scss"
import classNames from "classnames";

type propsType = {
    symbol: string
    children: React.ReactNode
    className?: string
}

const SymbolIndentedParagraph = (props: propsType) => {

    return (
        <div className={classNames(props.className)}>
            <div className={styles.symbol}>{props.symbol}</div>
            <div className={styles.text}>{props.children}</div>
        </div>
    );
};

export default SymbolIndentedParagraph;