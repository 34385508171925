import React from "react";

import * as styles from "./ThirdWidthCell.module.scss";
import classNames from "classnames";

type propsType = {
    children: React.ReactNode
    expandForMobile?: boolean
}

const ThirdWidthCell = (props: propsType) => {

    let rootClasses = classNames(
        styles.root,
        {[styles.expandForMobile]: props.expandForMobile}
    );

    return (<div className={rootClasses}>
        {props.children}
    </div>);
};

export default ThirdWidthCell;