import * as React from "react"
import {graphql} from "gatsby";
import {I18nContext, useTranslation} from 'gatsby-plugin-react-i18next';

import FullWidthContainer from "../components/grid/FullWidthContainer";
import Text from "../components/atoms/Text/Text";
import GridContainer from "../components/grid/GridContainer";
import HalfWidthCell from "../components/grid/HalfWidthCell";
import List from "../components/molecules/List/List";
import ColoredBox from "../components/molecules/ColoredTextBox/ColoredBox";
import Button from "../components/atoms/Button/Button";
import PageReferencesTitle from "../components/atoms/PageReferencesTitle/PageReferencesTitle";
import PageReferencesText from "../components/atoms/PageReferencesText/PageReferencesText";
import StageTeaser from "../components/molecules/StageTeaser/StageTeaser";
import Image from "../components/atoms/Image/Image";
import ThirdWidthCell from "../components/grid/ThirdWidthCell";
import SmallTextCarousel, {
	ImageSlideContent,
	SlideType
} from "../components/molecules/SmallTextCarousel/SmallTextCarousel";
import SymbolIndentedParagraph from "../components/atoms/SymbolIndentedParagraph/SymbolIndentedParagraph";
import TranslatedLink from "../components/util/TranslatedLink";
import Seo from "../components/global/Seo/Seo";

class propType {
	data: any
	classes: any
	context: any
}

const SymptomsPage = ({data}: propType) => {
	const {t} = useTranslation();


	const context = React.useContext(I18nContext);
	const currentLanguage = context.i18n.language;

	const symptomImages: ImageSlideContent[] = [
		{path: "/assets/images/symptoms/" +currentLanguage + "/splenomegaly.svg", altText: t('symptoms:sectionGuidingSymptoms.imageAltText1')},
		{path: "/assets/images/symptoms/" +currentLanguage + "/hepatomegaly.svg", altText: t('symptoms:sectionGuidingSymptoms.imageAltText2')},
		{path: "/assets/images/symptoms/" +currentLanguage + "/thrombozytopenia.svg", altText: t('symptoms:sectionGuidingSymptoms.imageAltText3')},
		{path: "/assets/images/symptoms/" +currentLanguage + "/anemia.svg", altText: t('symptoms:sectionGuidingSymptoms.imageAltText4')},
		{path: "/assets/images/symptoms/" +currentLanguage + "/bone-pain.svg", altText: t('symptoms:sectionGuidingSymptoms.imageAltText5')},
		{path: "/assets/images/symptoms/" +currentLanguage + "/ferritin.svg", altText: t('symptoms:sectionGuidingSymptoms.imageAltText6')},
	]

	return (
		<>
			<Seo title={t('symptoms:seo.title')} description={t('symptoms:seo.description')} />
			<FullWidthContainer>
				<StageTeaser image={data.stageTeaser} imageAlt={t('symptoms:imageAlt')} />
				<h1>{t('symptoms:mainHeadline')}</h1>
				<p>
					<Text text={t('symptoms:sectionIntroduction.copy1')} />
				</p>
				<p>
					<Text text={t('symptoms:sectionIntroduction.copy2')} />
				</p>
				<h2 className="margin-top-section"> <Text text={t('symptoms:sectionFollowingSymptoms.headline')}/></h2>
			</FullWidthContainer>
			<GridContainer>
				<HalfWidthCell expandforSmallOnly={true}>
					<h3 className="margin-top-0">{t('symptoms:sectionFollowingSymptoms.visceral.headline')}</h3>
					<List list={t('symptoms:sectionFollowingSymptoms.visceral.list', {returnObjects: true})} />
					<h3>{t('symptoms:sectionFollowingSymptoms.hematological.headline')}</h3>
					<List list={t('symptoms:sectionFollowingSymptoms.hematological.list', {returnObjects: true})} />
					<h3>{t('symptoms:sectionFollowingSymptoms.ossary.headline')}</h3>
					<List list={t('symptoms:sectionFollowingSymptoms.ossary.list', {returnObjects: true})} />
					<h3>{t('symptoms:sectionFollowingSymptoms.zns.headline')}</h3>
					<List list={t('symptoms:sectionFollowingSymptoms.zns.list', {returnObjects: true})} />
					<h3>{t('symptoms:sectionFollowingSymptoms.metabolism.headline')}</h3>
					<List list={t('symptoms:sectionFollowingSymptoms.metabolism.list', {returnObjects: true})} />
					<SymbolIndentedParagraph symbol="*" className="margin-top-4">
						<p>
							<Text text={"symptoms:sectionFollowingSymptoms.listFooter.copy"}/>
						</p>
					</SymbolIndentedParagraph>
				</HalfWidthCell>
				<HalfWidthCell expandforSmallOnly={true}>
					<Image imagePath={"/assets/images/symptoms/" + currentLanguage + "/organs.svg"} imageAlt={t('symptoms:sectionFollowingSymptoms.imageAltText')} />
				</HalfWidthCell>
			</GridContainer>
			<ColoredBox>
				<FullWidthContainer>
					<h2>{t('symptoms:sectionGuidingSymptoms.headline')}</h2>
					<p>
						<Text text={t('symptoms:sectionGuidingSymptoms.copy')}/>
					</p>
				</FullWidthContainer>
				<GridContainer className="hide-on-small">
					<ThirdWidthCell expandForMobile={true}>
						<Image imagePath={symptomImages[0].path} imageAlt={symptomImages[0].altText} />
					</ThirdWidthCell>
					<ThirdWidthCell expandForMobile={true}>
						<Image imagePath={symptomImages[1].path} imageAlt={symptomImages[1].altText} />
					</ThirdWidthCell>
					<ThirdWidthCell expandForMobile={true}>
						<Image imagePath={symptomImages[2].path} imageAlt={symptomImages[2].altText} />
					</ThirdWidthCell>
					<ThirdWidthCell expandForMobile={true}>
						<Image imagePath={symptomImages[3].path} imageAlt={symptomImages[3].altText} />
					</ThirdWidthCell>
					<ThirdWidthCell expandForMobile={true}>
						<Image imagePath={symptomImages[4].path} imageAlt={symptomImages[4].altText} />
					</ThirdWidthCell>
					<ThirdWidthCell expandForMobile={true}>
						<Image imagePath={symptomImages[5].path} imageAlt={symptomImages[5].altText} />
					</ThirdWidthCell>
				</GridContainer>
				<FullWidthContainer className="hide-on-medium-up">
					<SmallTextCarousel slides={symptomImages} slideType={SlideType.SVG_IMAGE} />
				</FullWidthContainer>
			</ColoredBox>
			<FullWidthContainer>
				<p className="margin-bottom-large">
					<Text text={'symptoms:sectionFinalText.copy'}/>
				</p>
			</FullWidthContainer>
			<FullWidthContainer>
				<TranslatedLink title={t('orderButton.title')} to="/order">
					<Button uppercase={true} inverted={true}>
						{t('symptoms:sectionReferences.buttonLabel')}
					</Button>
				</TranslatedLink>
				<PageReferencesTitle>
					<Text text="symptoms:sectionReferences.headline" />
				</PageReferencesTitle>
				<PageReferencesText>
					<Text text="symptoms:sectionReferences.copy" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="symptoms:sectionReferences.explanation" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="approvalCode" />
				</PageReferencesText>
			</FullWidthContainer>
		</>
	)
}

export default SymptomsPage

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "symptoms"]}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		},
		stageTeaser: file(
			relativePath: { eq: "symptoms/StageTeaserDesktop.jpg"}
		) {
			id
			relativePath
			childImageSharp {
				gatsbyImageData(
					placeholder: BLURRED,
					formats: [AUTO, WEBP, AVIF],
					quality: 75,
					sizes: "(min-width: 1300px) 1292px, (min-width: 640px) 100vw,  200vw"

				)
			}
		}
	}
`;
